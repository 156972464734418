export default {
  name: 'gmk-terminal',
  override: {
    KC_H: 'accent',
    KC_J: 'accent',
    KC_K: 'accent',
    KC_L: 'accent',
    KC_ESC: 'accent',
    KC_ENT: 'accent'
  }
};
