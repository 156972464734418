export default {
  name: 'gmk-metaverse',
  override: {
    KC_ESC: 'accent',
    KC_ENT: 'accent',
    KC_F1: 'mod',
    KC_F2: 'mod',
    KC_F3: 'mod',
    KC_F4: 'mod',
    KC_F5: 'accent',
    KC_F6: 'accent',
    KC_F7: 'accent',
    KC_F8: 'accent',
    KC_F9: 'mod',
    KC_F10: 'mod',
    KC_F11: 'mod',
    KC_F12: 'mod',
    KC_LGUI: 'accent',
    KC_RGUI: 'accent',
    KC_LEFT: 'accent',
    KC_RGHT: 'accent',
    KC_DOWN: 'accent',
    KC_UP: 'accent',
    KC_P0: 'mod',
    KC_P1: 'mod',
    KC_P2: 'mod',
    KC_P3: 'mod',
    KC_P4: 'mod',
    KC_P5: 'mod',
    KC_P6: 'mod',
    KC_P7: 'mod',
    KC_P8: 'mod',
    KC_P9: 'mod',
    KC_PDOT: 'mod',
    KC_PENT: 'accent'
  }
};
