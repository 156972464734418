<template>
  <div>
    <div class="split-content">
      <div class="left-side">
        <layerControl />
      </div>
      <div class="right-side">
        <div class="keymap--area">
          <!--label class="keymap--label" :title="$t('ColorwayTip.title')">
            {{ $t('keymap.label') }}:
            <font-awesome-icon
              v-if="continuousInput"
              icon="keyboard"
              fixed-width
            />
          </label-->
          &nbsp;
          <!-- maintain spacing for paragraph -->

          <label
            :title="
              $t('settingsPanel.fastInput.title') +
                $t('settingsPanel.fastInput.help')
            "
            >{{ $t('settingsPanel.fastInput.label') }}:</label
          >
          <toggle-button
            id="setting-toggle-fast-input"
            :value="continuousInput"
            :width="55"
            :sync="true"
            :labels="labels"
            @change="toggleContinuousInput"
			color="#789EC2"
          />
          &nbsp;
          <label
            :title="
              $t('settingsPanel.displaySizes.title') +
                $t('settingsPanel.displaySizes.help')
            "
            >{{ $t('settingsPanel.displaySizes.label') }}:</label
          >
          <toggle-button
            id="setting-toggle-display-size"
            :value="displaySizes"
            :width="55"
            :sync="false"
            :labels="labels"
            @change="toggleDisplaySizes"
			color="#789EC2"
          />
          <!-- select
            class="keymap--keyset"
            id="colorway-select"
            v-model="curIndex"
            :title="$t('ColorwayTip.title')"
          >
            <option
              class="option"
              v-for="(name, index) in displayColorways"
              :key="index"
              :value="index"
              >{{ name }}</option
            >
          </select>
          <a
            id="favorite-colorway"
            :title="$t('favoriteColor')"
            @click="favColor"
            :class="{
              active: isFavoriteColor
            }"
          >
            <font-awesome-icon icon="star" size="lg" fixed-width />
          </a -->
        </div>
        <!--KeyboardMain />
        <LEDSetting /-->
        <visualKeymap :profile="false" />
        <!--span class="keymap--count"
          ><span class="keymap--counter">{{ keyCount }}</span
          >Keys</span
        -->
      </div>
    </div>
    <div>
      <keycodes />
    </div>
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize';
import { ToggleButton } from 'vue-js-toggle-button';
import {
  mapMutations as _mapMutations,
  createNamespacedHelpers,
  mapState as _mapState,
  mapGetters as _mapGetters,
  mapActions
} from 'vuex';
const { mapState, mapGetters, mapMutations } = createNamespacedHelpers(
  'keymap'
);
// import LEDSetting from '@/components/LEDSetting';
// import KeyboardMain from '@/components/KeyboardMain';
// import ControllerTop from '@/components/ControllerTop';
// import StatusPanel from '@/components/StatusPanel';
// import ControllerBottom from '@/components/ControllerBottom';
import Keycodes from '@/components/Keycodes';
import VisualKeymap from '@/components/VisualKeymap';
import LayerControl from '@/components/LayerControl';
import * as jquery from '@/jquery';

export default {
  name: 'KeymapPanel',
  props: {},
  data() {
    return {
      labels: {
        checked: this.$t('settingsPanel.on.label'),
        unchecked: this.$t('settingsPanel.off.label')
      }
    };
  },
  components: {
    // LEDSetting,
    // KeyboardMain,
    // ControllerTop,
    // StatusPanel,
    // ControllerBottom,
    Keycodes,
    VisualKeymap,
    LayerControl,
    ToggleButton
  },
  computed: {
    ..._mapState('app', ['configuratorSettings', 'displaySizes']),
    ..._mapGetters('app', ['keyCount']),
    ...mapState(['continuousInput']),
    ...mapGetters(['colorwayIndex', 'colorways', 'size']),
    curIndex: {
      get() {
        return this.colorwayIndex;
      },
      set(value) {
        this.nextColorway(value);
      }
    },
    displayColorways() {
      return this.colorways.map(keyset => {
        return keyset
          .replace(/-/g, ' ')
          .split(' ')
          .map(word => capitalize(word))
          .join(' ')
          .replace(/Gmk/, 'GMK')
          .replace(/^Sa/, 'SA')
          .replace(/^Dsa/, 'DSA')
          .replace(/Wob/, 'WOB')
          .replace(/Ta/, 'TA');
      });
    },
    redditPost() {
      return 'https://www.reddit.com/r/MechanicalKeyboards/comments/aio97b/qmk_configurator_updates_beta_need_your_input/';
    },
    isFavoriteColor() {
      return (
        this.configuratorSettings.favoriteColor &&
        this.displayColorways[this.curIndex].toLowerCase() ===
          this.configuratorSettings.favoriteColor.toLowerCase()
      );
    }
  },
  methods: {
    ...mapActions('app', ['setFavoriteColor']),
    ...mapMutations(['nextColorway']),
    ..._mapMutations('keymap', ['toggleDisplaySizes', 'toggleContinuousInput']),
    ..._mapMutations('app', ['resetListener']),
    favColor() {
      if (this.isFavoriteColor) {
        this.setFavoriteColor('');
      } else {
        this.setFavoriteColor(this.displayColorways[this.curIndex]);
      }
    }
  },
  mounted() {
    jquery.init();
    // Loading favorite color
    // if (this.configuratorSettings.favoriteColor) {
    //   const favoriteColor = this.configuratorSettings.favoriteColor.toLowerCase();
    //   this.curIndex = this.displayColorways.findIndex(
    //     color => color.toLowerCase() === favoriteColor
    //   );
    // }
    this.curIndex = 0;
  },
  beforeDestroy() {
    this.resetListener();
  }
};
</script>
<style>
#colorway-select {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.keymap--label {
  float: left;
}
.keymap--counter {
  display: inline-block;
  padding: 0 5px;
  margin-top: 2px;
}
.keymap--count {
  float: right;
  color: #999;
}
.keymap--keyset {
  float: right;
}
.keymap--area {
  margin-top: 1em;
  margin-bottom: 1em;
  height: 1.5em;
  text-align: left;
}
</style>
