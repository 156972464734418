<template>
<div>
  <div>
    <div
      class="macros"
      v-for="(macro, index) in this.uiMacros"
      :key="index" 
    >
      <label class="macrosidx">
        {{$t('macroTitle.label') }}{{index}}: 
      </label>
      <div class="a-macro">
        <label class="macrosidx" v-if="isEmptyMacro(macro)">
          {{ $t('macroEmpty.label') }}
        </label>
        <div class="macro-steps">
          <template
            v-for="(step) in macro"
          >
          <component
              v-bind:is="getComponent(step)"
              v-bind="step"
              :key="step.id"
            />
          {{step.name}}
          </template>
        </div>
        <div class="marco-ctl">
          <select ref="selAct">
            <option class="option" value="tap">{{ $t('macroStepTypeTap.label') }}</option>
            <option class="option" value="down">{{ $t('macroStepTypeDown.label') }}</option>
            <option class="option" value="up">{{ $t('macroStepTypeUp.label') }}</option>
            <option class="option" value="wait">{{ $t('macroStepTypeDelay.label') }}</option>
            <option class="option" value="str">{{ $t('macroStepTypeStr.label') }}</option>
          </select>
          <button
            class="green-btn btn-s"
            @click="addStep(index)"
          >
          <font-awesome-icon icon="plus-square" fixed-width />
          {{ $t('macroAddStep.label') }}
          </button>
          <button
            class="green-btn btn-s"
            @click="removeMacroById(index)"
          >
          <font-awesome-icon icon="minus-square" inverse fixed-width />
          {{ $t('macroDel.label') }}
          </button>
        </div>
      </div>
    </div>
    <div class="addmacro">
      <button
        id="addNewMacro"
        class="green-btn fixed-size"
        @click="addNewMacro"
      >
      <font-awesome-icon icon="clone" size="lg" fixed-width />
          {{ $t('macroAddNew.label') }}
      </button>
      <button
        class="green-btn fixed-size"
        @click="openHelp"
      >
      <font-awesome-icon icon="info" size="lg" fixed-width />
          {{ $t('macroGuide.label') }}
      </button>
      <a
        ref="helpLink"
        :href="helpLink"
        target="xbowskbwiki"
      />
    </div>
    <!--<div>{{exportMacros}}</div>-->
    <div>
      <MacroKeycodes />
    </div>
  </div>
</div>
</template>
<script>
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import * as jquery from '@/jquery';
import MacroStepKey from '@/components/MacroStepKey';
import MacroKeycodes from '@/components/MacroKeycodes';
export default {
  name: 'macros',
  data() {
    return {
    };
  },
  components: {
    MacroKeycodes
  },
  computed: {
    ...mapGetters('keymap', ['colorway']), 
    ...mapGetters('keyboardSetting', ['exportMacros']),    
    ...mapState('keyboardSetting', ['macros']),
    ...mapState('app', ['configuratorSettings']),
    helpLink() {
      let lang = this.configuratorSettings.language;
      if(lang==undefined)
        lang = "en";
      return `../${lang}/wiki/macro.html`;
    },
    uiMacros() {
      let ret = this.macros.map(
        (macro, pos) => {
          for(let i=0; i<macro.length; i++) {
            macro[i] = Object.assign(macro[i],
              {
                colorway:this.colorway,
                w: 0,
                h: 40
              }
            );
          }
          return macro;
        }
      );
      return ret;
    },
    isEmptyMacro() {
      return function( macro) {
        return !(macro.length>0);
      }      
    }
  },
  methods: {
    ...mapMutations('app', ['resetListener']),
    addNewMacro: function() {
      if(this.macros.length>=32) {
        return alert('最多只可添加32个宏。');
      }
      this.$store.commit('keyboardSetting/addNewMacro');
    },
    removeMacroById: function(idx) {
      this.$store.commit('keyboardSetting/removeMacroById', idx);
    },
    addStep: function(macroId) {
      let actType = this.$refs.selAct[macroId].value;
      const colorway = this.colorway;
      this.$store.commit('keyboardSetting/addMacroStep', 
              {macroId, actType });
    },
    getComponent(step) {
          return MacroStepKey;
    },
    openHelp() {
        this.$refs.helpLink.click();
      // Vue.nextTick(() => {});
      
    },
  },
  mounted() {
    jquery.init(); // 初始化按键监听，有按键时调用jquery.keydownHandler()
  },
  beforeDestroy() {
    this.resetListener();
  }
};
</script>
<style>
.green-btn {
    background-color: #789EC2;
    color: white;
    border-color: #000;
    line-height: 120%;
    border-radius: 3px;
    border: 0px solid #000;
    padding: 5px 8px;
    cursor: pointer;
}
.btn-s {
  min-width: 60px;
  margin: auto 4px;
}
.macros {
  margin:5px;padding:10px;border: 1px solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.macrosidx {
  white-space: nowrap; line-height:55px;
}
.a-macro {
  -webkit-box-flex: 5;
  -ms-flex-positive: 5;
  flex-grow:5;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display:inline-flex;
}
.macro-steps {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow:1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display:flex;
}
.marco-ctl {
  line-height:55px;
  -ms-flex-negative: 0;
  flex-shrink:0;
}
.marco-ctl select {
  margin: auto 6px; height:24px;
}
.addmacro {
  margin-left:5px;margin-bottom:20px;
}
</style>