<template>
  <div>
    <div ref="console">
      <controllerTop v-if="appInitialized" />
      <controllerBottom />
    </div>
    <!--div class="hint hint-right">
      <a target="_blank" href="https://github.com/qmk/qmk_toolbox/releases">
        {{ $t('downloadToolbox.label') }}
      </a>
    </div-->
    <KeyboardMain />
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize';
import { ToggleButton } from 'vue-js-toggle-button';
import {
  mapMutations as _mapMutations,
  createNamespacedHelpers,
  mapState as _mapState,
  mapGetters as _mapGetters,
  mapActions
} from 'vuex';
// import LEDSetting from '@/components/LEDSetting';
import KeyboardMain from '@/components/KeyboardMain';
import ControllerTop from '@/components/ControllerTop';
import ControllerBottom from '@/components/ControllerBottom';
////////////////import VisualKeymap from '@/components/VisualKeymap';
// import LayerControl from '@/components/LayerControl';
// import * as jquery from '@/jquery';

export default {
  name: 'Main',
  props: {},
  components: {
    // LEDSetting,
    // KeymapPanel,
    KeyboardMain,
    ControllerTop,
    ControllerBottom
    ///////////VisualKeymap,
    // LayerControl,
    // ToggleButton
  },
  computed: {
    ..._mapState('app', ['appInitialized'])
  },
  methods: {
    ..._mapMutations('app', ['resetListener'])
  },
  // mounted() {
  //   jquery.init();
  // },
  beforeDestroy() {
    this.resetListener();
    console.log('beforeDestroy() in Main.vue');
  }
};
</script>
<style>
.hint-right {
  display: grid;
  justify-content: end;
}
</style>
