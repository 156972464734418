<template>
  <!-- prettier-ignore -->
  <div
    draggable
    :id="myid"
    class="key key-layer"
    :class="myclasses"
    :style="mystyles"
    @click="clicked"
    @dragstart="dragstart"
    @dragend="dragend"
    @drop.stop="dropped"
    @dragleave.prevent="dragleave"
    @dragover.prevent="dragover"
    @dragenter.prevent="dragenter"
    ><div>{{ displayName }}<div><input
    class="key-layer-input"
    :class="errorClasses"
    type="number"
    min="0"
    max="31"
    :value="value"
    @focus="focus"
    @blur="blur"
    @input="input"
    /></div></div><div
        v-if="visible"
        class="remove"
        @click.stop="remove"
      >x</div>
  </div>
</template>
<script>
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
import { mapState, mapMutations, mapActions } from 'vuex';
import BaseKey from './BaseKey';
export default {
  name: 'macro-key',
  extends: BaseKey,
  data() {
    return {
      error: false
    };
  },
  computed: {
    ...mapState('keymap', {
      curLayer: 'layer'
    }),
    value() {
      return this.meta.mid;
    },
    errorClasses() {
      if (this.error) {
        return 'input-error';
      }
      return '';
    }
  },
  methods: {
    ...mapMutations('app', ['setHasErrors', 'setHasNoErrors']),
    ...mapMutations('keymap', ['setText']),
    ...mapActions('keymap', ['setKeycodeMacro']),
    input(e) {
      const newId = parseInt(e.target.value, 10);
      if (!isNaN(newId) && isNumber(newId)) {
        this.error = newId < 0 || newId > 31;
        if (!this.error) {
          // don't set keycode layer if error
          this.setKeycodeMacro({
            layer: this.curLayer,
            index: this.id,
            newId
          });
        }
      }
      if (this.error) {
        this.setHasErrors();
      } else {
        this.setHasNoErrors();
      }
    },
    blur() {
      this.startListening();
      this.setSelected(undefined);
    },
    focus() {
      this.stopListening();
    }
  }
};
</script>
