export default {
  name: 'sa-danger-zone',
  override: {
    KC_ESC: 'accent-red',
    KC_ENT: 'accent-yellow',
    KC_UP: 'key',
    KC_LEFT: 'key',
    KC_DOWN: 'key',
    KC_RGHT: 'key'
  }
};
