export default [
  { label: 'MacroAppMedia', width: 'label', group: true },

  { label: 'Application', width: 'label' },

  { name: 'Power', code: 'KC_PWR', title: 'System Power Down' },
  { name: 'Sleep', code: 'KC_SLEP', title: 'System Sleep' },
  { name: 'Wake', code: 'KC_WAKE', title: 'System Wake' },
  { width: 1250 },
  { name: 'Exec', code: 'KC_EXEC', title: 'Execute' },
  { name: 'Help', code: 'KC_HELP', title: 'Help' },
  { name: 'Menu', code: 'KC_MENU', title: 'Menu (Legacy)' },
  { name: 'Select', code: 'KC_SLCT', title: 'Select' },
  { name: 'Stop', code: 'KC_STOP', title: 'Stop' },
  { name: 'Again', code: 'KC_AGIN', title: 'Again' },
  { name: 'Undo', code: 'KC_UNDO', title: 'Undo' },
  { name: 'Cut', code: 'KC_CUT', title: 'Cut' },
  { name: 'Copy', code: 'KC_COPY', title: 'Copy' },
  { name: 'Paste', code: 'KC_PSTE', title: 'Paste' },
  { name: 'Find', code: 'KC_FIND', title: 'Find' },
  { width: 0 },
  {
    name: 'Calc',
    code: 'KC_CALC',
    title: 'Launch Calculator (Windows)'
  },
  {
    name: 'Mail',
    code: 'KC_MAIL',
    title: 'Launch Mail (Windows)'
  },
  {
    name: 'Media Player',
    code: 'KC_MSEL',
    title: 'Launch Media Player (Windows)'
  },
  {
    name: 'My PC',
    code: 'KC_MYCM',
    title: 'Launch My Computer (Windows)'
  },
  { width: 250 },
  {
    name: 'Browser Search',
    code: 'KC_WSCH',
    title: 'Browser Search (Windows)',
    width: 1500
  },
  {
    name: 'Browser Home',
    code: 'KC_WHOM',
    title: 'Browser Home (Windows)',
    width: 1500
  },
  {
    name: 'Browser Back',
    code: 'KC_WBAK',
    title: 'Browser Back (Windows)',
    width: 1500
  },
  {
    name: 'Browser Forward',
    code: 'KC_WFWD',
    title: 'Browser Forward (Windows)',
    width: 1500
  },
  {
    name: 'Browser Stop',
    code: 'KC_WSTP',
    title: 'Browser Stop (Windows)',
    width: 1500
  },
  {
    name: 'Browser Refresh',
    code: 'KC_WREF',
    title: 'Browser Refresh (Windows)',
    width: 1500
  },
  {
    name: 'Browser Favorites',
    code: 'KC_WFAV',
    title: 'Browser Favorites (Windows)',
    width: 1500
  },

  { label: 'Multimedia Keys', width: 'label' },

  { name: 'Previous', code: 'KC_MPRV', title: 'Previous Track' },
  { name: 'Next', code: 'KC_MNXT', title: 'Next Track' },
  { name: 'Mute', code: 'KC_MUTE', title: 'Mute Audio' },
  { name: 'Vol -', code: 'KC_VOLD', title: 'Volume Down' },
  { name: 'Vol +', code: 'KC_VOLU', title: 'Volume Up' },
  { name: 'Media Stop', code: 'KC_MSTP', title: 'Media Stop' },
  { name: 'Play', code: 'KC_MPLY', title: 'Play/Pause' },
  { width: 250 },
  {
    name: 'Prev Track',
    code: 'KC_MRWD',
    title: 'Previous Track / Rewind (macOS)'
  },
  {
    name: 'Next Track',
    code: 'KC_MFFD',
    title: 'Next Track / Fast Forward (macOS)'
  },
  { width: 250 },
  { name: 'Eject', code: 'KC_EJCT', title: 'Eject (macOS)' },


  { label: 'Extra Fn Keys', width: 'label' },

  { name: 'F13', code: 'KC_F13', title: 'F13' },
  { name: 'F14', code: 'KC_F14', title: 'F14' },
  { name: 'F15', code: 'KC_F15', title: 'F15' },
  { name: 'F16', code: 'KC_F16', title: 'F16' },
  { name: 'F17', code: 'KC_F17', title: 'F17' },
  { name: 'F18', code: 'KC_F18', title: 'F18' },
  { name: 'F19', code: 'KC_F19', title: 'F19' },
  { name: 'F20', code: 'KC_F20', title: 'F20' },
  { name: 'F21', code: 'KC_F21', title: 'F21' },
  { name: 'F22', code: 'KC_F22', title: 'F22' },
  { name: 'F23', code: 'KC_F23', title: 'F23'  },
  { name: 'F24', code: 'KC_F24', title: 'F24'  }
];
