<template>
    <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" href="/">{{ $t('pageNavBar.home.label') }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="//actuationkeyboards.com/">{{ $t('pageNavBar.store.label') }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :href="channelLink('wiki')">{{ $t('pageNavBar.wiki.label') }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :href="channelLink('fw')">{{ $t('pageNavBar.firmware.label') }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="../config/">{{ $t('pageNavBar.config.label') }}</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownlang" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ $t('pageNavBar.lang.label') }}</a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownlang">
              <span 
                class="dropdown-item" 
                @click="changeLanguage('en')">
                English
              </span>
              <span 
                class="dropdown-item" 
                @click="changeLanguage('zh')">
                中文
              </span>
            </div>
          </li>
        </ul>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'page-navbar',
  computed: {
    ...mapState('app', ['configuratorSettings']),
    channelLink() {
      return function(ch) {
        //get() {
        let lang = this.configuratorSettings.language;
        if(lang==undefined)
          lang = "en";
        if(ch===undefined)
          return `/${lang}/`;
        else
          return `../${lang}/${ch}/`;      
        // },
        // async set(value) {
        //   await this.changeLanguage(value);
        // }
      }
    }
  },
  methods: {
    ...mapActions('app', ['changeLanguage']),
    setLanguage(val) {
      this.changeLanguage(val);
    }
  }
};
</script>
<style>
.dropdown-menu span {
  cursor: pointer;
  width: auto;
}
</style>