<template>
  <div class="led-setting">
    <div>
      <label>无线时灯自动熄灭: </label>
      <toggle-button
        color="#78C2AD"
        v-model="kbSetting.lightAutoOff"
        :width="55"
        :labels="labels"
      />
    </div>
    <div v-if="ledCount > 0" class="leds">
      <div v-for="(key, idx) in tmpLeds" :key="idx">
        <label>LED{{ idx }}: </label>
        <select :value="getFnAt(idx)" @input="handleFnSelect(idx, $event)">
          <option
            class="option"
            v-for="(item, index) in ledOptions"
            :key="index"
            :value="item.LEDName"
            >{{ item.title }}</option
          >
        </select>        
        <toggle-button
          color="#78C2AD"
          v-model="key.en"
          :width="55"
          :labels="labels"
        />
        <span>
          <label v-bind:class="{ 'disable-label': !key.en }">无线时:</label>
          <toggle-button
            color="#78C2AD"
            v-model="key.enwl"
            :width="55"
            :labels="labels"
            v-bind:disabled="!key.en"
          />
        </span>
      </div>
    </div>
    <div v-if="rgbCount > 0" class="rgbs">
      <div v-for="(key, idx) in tmpRgbs" :key="idx">
        <div class="rgbblock">
          <input type="hidden" :value="idx" />
          <label>{{ key.title }}: </label>
          <input
            ref="rgbbar"
            type="text"
            v-model="key.color"
            class="colorbar"
            :style="'background-color: ' + key.color"
            @focus="showPicker(key)"
            @input="updateFromInput(key)"
          />
          <span ref="picker">
            <transition name="fade" appear :key="idx">
              <chrome-picker
                class="colorpicker"
                disableAlpha
                disableFields
                v-if="key.showpicker"
                v-model="key.tmpcolor"
                @input="updateFromPicker(key)"
              />
            </transition>
          </span>
          <toggle-button
            color="#78C2AD"
            v-model="key.en"
            :width="55"
            :labels="labels"
          /><!--label>启用</label-->
          <span>
            <label v-bind:class="{ 'disable-label': !key.en }">无线时:</label>
            <toggle-button
              color="#78C2AD"
              v-model="key.enwl"
              :width="55"
              :labels="labels"
              v-bind:disabled="!key.en"
            />
          </span>
          <transition name="fade" appear :key="idx"> </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ToggleButton } from 'vue-js-toggle-button';
import { Chrome } from 'vue-color';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'LEDSetting',
  components: {
    ToggleButton,
    'chrome-picker': Chrome
  },
  data() {
    return {
      tmprgb: { a: { k: 123 }, b: 444 },
      ledOptions: [
        { LEDName: 'CAPSLOCK', title: 'CAPSLOCK', desc: 'CAPSLOCK' },
        { LEDName: 'NUMLOCK', title: 'NUMLOCK', desc: 'NUMLOCK' },
        { LEDName: 'LAYER1', title: '层1', desc: 'LAYER1' },
        { LEDName: 'LAYER2', title: '层2', desc: 'LAYER2' },
        { LEDName: 'LAYER3', title: '层3', desc: 'LAYER3' },
        { LEDName: 'LAYER4', title: '层4', desc: 'LAYER4' },
        { LEDName: 'LAYER5', title: '层5', desc: 'LAYER5' },
        { LEDName: 'LAYER6', title: '层6', desc: 'LAYER6' }
      ],
      tmpLeds: [{
			"fn": "CAPSLOCK",
			"en": true,
			"enwl": true
		}, {
			"fn": "LAYER1",
			"en": true,
			"enwl": true
		}, {
			"fn": "LAYER2",
			"en": true,
			"enwl": true
		}, {
			"fn": "LAYER3",
			"en": true,
			"enwl": true
		}, {
			"fn": "LAYER4",
			"en": true,
			"enwl": true
		}, {
			"fn": "LAYER5",
			"en": true,
			"enwl": true
		}, {
			"fn": "LAYER6",
			"en": true,
			"enwl": true
		}],
      tmpRgbs: {
        LAYER1: {
          color: null,
          en: true,
          enwl: false,
          /*  */ title: '层1',
          showpicker: false,
          tmpcolor: null
        },
        LAYER2: {
          color: null,
          en: true,
          enwl: false,
          /*  */ title: '层2',
          showpicker: false,
          tmpcolor: null
        },
        LAYER3: {
          color: null,
          en: true,
          enwl: false,
          /*  */ title: '层3',
          showpicker: false,
          tmpcolor: null
        },
        LAYER4: {
          color: null,
          en: true,
          enwl: false,
          /*  */ title: '层4',
          showpicker: false,
          tmpcolor: null
        },
        LAYER5: {
          color: null,
          en: true,
          enwl: false,
          /*  */ title: '层5',
          showpicker: false,
          tmpcolor: null
        },
        LAYER6: {
          color: null,
          en: true,
          enwl: false,
          /*  */ title: '层6',
          showpicker: false,
          tmpcolor: null
        }
      },
      labels: {
        checked: '启用',
        unchecked: '关闭'
      },
      kbSetting: { lightAutoOff: true }
    };
  },
  created() {
    console.log("LEDSetting created.");
    for (var key in this.rgbs) {
      this.tmpRgbs[key].color = this.rgbs[key].color + '';
      this.tmpRgbs[key].tmpcolor = this.rgbs[key].color + '';
      this.tmpRgbs[key].en = this.rgbs[key].en ? true : false;
      this.tmpRgbs[key].enwl = this.rgbs[key].enwl ? true : false;
    }
    for ( var idx=0; idx<this.ledCount; idx++ ) {
      this.tmpLeds[idx].id = idx;
      if( this.leds[idx]!==undefined ) {
        this.tmpLeds[idx].fn = this.leds[idx].fn;
        this.tmpLeds[idx].en = this.leds[idx].en;
        this.tmpLeds[idx].enwl = this.leds[idx].enwl;
      }
      else {        
        this.tmpLeds[idx].fn = "LAYER1";
        this.tmpLeds[idx].en = false;
        this.tmpLeds[idx].enwl = false;
      }
    }
    this.kbSetting.lightAutoOff = this.keyboardSetting.lightAutoOff;
  },
  mounted() {
    console.log("LEDSetting mounted.");
    this.$watch('tmpRgbs', {
      handler(newRgbs, oldRgbs) {
        this.$store.commit('keyboardSetting/updateRgbAt', newRgbs);
        // this.updateRgbAt(newRgbs);
      },
      deep: true
    });
    this.$watch('tmpLeds', {
      handler(newLeds, oldLeds) {
        this.$store.commit('keyboardSetting/setLeds', newLeds);
        // this.updateRgbAt(newRgbs);
      },
      deep: true
    });
    this.$watch('kbSetting', {
      handler(newRgbs, oldRgbs) {
        this.$store.commit('keyboardSetting/updateKeyboardSetting', newRgbs);
      },
      deep: true
    });
  },
  computed: {
    ...mapGetters('app', ['ledCount', 'rgbCount']),
    ...mapState('keyboardSetting', ['leds', 'rgbs', 'keyboardSetting']),
    ...mapGetters('keyboardSetting', ['ledSettingCount', 'rgbSettingCount']),
    ledID() {
      return function(idx) {
        return 'LED' + idx;
      };
    },
    rgbID() {
      return function(key) {
        return this.tmpRgbs[key].title;
      };
    }
  },
  methods: {
    ...mapActions('keyboardSetting', ['updateLedAt', 'updateRgbAt']),
    handleFnSelect(id, e) {
      // this.$store.commit('keyboardSetting/updateLedAt', {
      //   id: id,
      //   fn: e.target.value
      // });
      this.tmpLeds[id].fn = e.target.value;
      // this.updateLedAt({id:id,fn:e.target.value});
    },
    getLedSettingAt(id, key) {
      if (this.leds[id]) return this.leds[id][key];
    },
    handleLedSettingValue(id, key, e) {
      let val = {};
      val['id'] = id;
      val[key] = e.target.checked;
      this.$store.commit('keyboardSetting/updateLedAt', val);
      // this.$store.commit('keyboardSetting/updateLedAt', {id:id,key:e.target.checked});
      // this.updateLedAt({id:id,enwl:e.target.checked});
    },
    getFnAt(id) {
      if (this.leds[id]) return this.leds[id].fn;
    },
    handleEnwlCB(id, e) {
      this.$store.commit('keyboardSetting/updateLedAt', {
        id: id,
        enwl: e.target.checked
      });
      // this.updateLedAt({id:id,enwl:e.target.checked});
    },
    getEnwlAt(id) {
      if (this.leds[id]) return this.leds[id].enwl;
    },
    showPicker(rgbdata) {
      for (var key in this.tmpRgbs) {
        if (this.tmpRgbs[key].showpicker) {
          this.hidePicker(this.tmpRgbs[key]);
          //return;
        }
      }
      document.addEventListener('click', this.documentClick);
      rgbdata.showpicker = true;
    },
    hidePicker(rgbdata) {
      document.removeEventListener('click', this.documentClick);
      rgbdata.showpicker = false;
    },
    togglePicker(rgbdata) {
      rgbdata.showpicker ? this.hidePicker(rgbdata) : this.showPicker(rgbdata);
    },
    updateFromInput(key) {
      if (key.tmpcolor !== key.color) key.tmpcolor = key.color;
      // this.updateColors(this.colorValue);
    },
    updateFromPicker(key) {
      key.tmpcolor = key.color = key.tmpcolor.hex;
      // this.colors = color;
      // if(color.rgba.a == 1) {
      // 	this.colorValue = color.hex;
      // }
      // else {
      // 	this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      // }
    },
    documentClick(e) {
      if( this.ledCount<=0 )
        return;
      for (var i = 0; i < this.$refs.rgbbar.length; i++) {
        if (e.target == this.$refs.rgbbar[i]) return;
      }
      for (var i = 0; i < this.$refs.picker.length; i++) {
        if (this.$refs.picker[i].contains(e.target)) return;
      }
      // var el = this.$refs.colorpicker,
      // 	target = e.target;
      // if(el !== target && !el.contains(target)) {
      //   return;
      // }
      // if(e.target.type=="text") return;

      for (var key in this.tmpRgbs) {
        if (this.tmpRgbs[key].showpicker) {
          this.hidePicker(this.tmpRgbs[key]);
          return;
        }
      }
    }
  }
};
</script>
<style scoped>
.rgbblock {
  position: relative;
  width: 500px;
}
.colorpicker {
  position: absolute;
  left: 40px;
  top: 22px;
  z-index: 9;
}
.colorbar {
  color: white;
  border-width: 0px;
  margin-right: 15px;
}
.disable-label {
  opacity: 0.6;
}
.leds {
  margin-top: 15px;
}
.rgbs {
  margin-top: 15px;
}
.led-setting {
  margin:15px;
}
.led-setting input[type='checkbox'] {
  margin-left: 10px;
}
.vue-js-switch {
  margin-left: 5px;
  margin-right: 15px;
}
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
