export default [
  { label: 'ANSI', width: 'label', group: true },

  { name: 'Esc', code: 'KC_ESC', keys: 'esc', title: 'esc' },
  { width: 1000 },
  { name: 'F1', code: 'KC_F1', keys: 'f1', title: 'F1' },
  { name: 'F2', code: 'KC_F2', keys: 'f2', title: 'F2' },
  { name: 'F3', code: 'KC_F3', keys: 'f3', title: 'F3' },
  { name: 'F4', code: 'KC_F4', keys: 'f4', title: 'F4' },
  { width: 500 },
  { name: 'F5', code: 'KC_F5', keys: 'f5', title: 'F5' },
  { name: 'F6', code: 'KC_F6', keys: 'f6', title: 'F6' },
  { name: 'F7', code: 'KC_F7', keys: 'f7', title: 'F7' },
  { name: 'F8', code: 'KC_F8', keys: 'f8', title: 'F8' },
  { width: 500 },
  { name: 'F9', code: 'KC_F9', keys: 'f9', title: 'F9' },
  { name: 'F10', code: 'KC_F10', keys: 'f10', title: 'F10' },
  { name: 'F11', code: 'KC_F11', keys: 'f11', title: 'F11' },
  { name: 'F12', code: 'KC_F12', keys: 'f12', title: 'F12' },
  { width: 250 },
  { name: 'Print Screen', code: 'KC_PSCR', title: 'Print Screen' },
  { name: 'Scroll Lock', code: 'KC_SLCK', title: 'Scroll Lock' },
  { name: 'Pause', code: 'KC_PAUS', title: 'Pause' },
  { width: 0 },

  { name: '~\n`', code: 'KC_GRV', keys: '`', title: '`' },
  { name: '!\n1', code: 'KC_1', keys: '1', title: '1' },
  { name: '@\n2', code: 'KC_2', keys: '2', title: '2' },
  { name: '#\n3', code: 'KC_3', keys: '3', title: '3' },
  { name: '$\n4', code: 'KC_4', keys: '4', title: '4' },
  { name: '%\n5', code: 'KC_5', keys: '5', title: '5' },
  { name: '^\n6', code: 'KC_6', keys: '6', title: '6' },
  { name: '&\n7', code: 'KC_7', keys: '7', title: '7' },
  { name: '*\n8', code: 'KC_8', keys: '8', title: '8' },
  { name: '(\n9', code: 'KC_9', keys: '9', title: '9' },
  { name: ')\n0', code: 'KC_0', keys: '0', title: '0' },
  { name: '_\n-', code: 'KC_MINS', keys: '-', title: '_ -' },
  { name: '+\n=', code: 'KC_EQL', keys: '=', title: '+ =' },
  { name: 'Back Space', code: 'KC_BSPC', keys: 'backspace', width: 2000 , title: 'backspace' },
  { width: 250 },
  { name: 'Insert', code: 'KC_INS', keys: 'insert', title: 'Insert' },
  { name: 'Home', code: 'KC_HOME', keys: 'home', title: 'Home' },
  { name: 'Page Up', code: 'KC_PGUP', keys: 'pageup', title: 'Page Up' },
  { width: 250 },
  { name: 'Num Lock', code: 'KC_NLCK', keys: 'num', title: 'Num Lock' },
  { name: '/', code: 'KC_PSLS', keys: 'num_divide', title: 'numpad /' },
  { name: '*', code: 'KC_PAST', keys: 'num_multiply', title: 'numpad *' },
  { name: '-', code: 'KC_PMNS', keys: 'num_subtract', title: 'numpad -' },
  { width: 0 },

  { name: 'Tab', code: 'KC_TAB', keys: 'tab', width: 1500, title: 'Tab' },
  { name: 'q', code: 'KC_Q', keys: 'q', title: 'q' },
  { name: 'w', code: 'KC_W', keys: 'w', title: 'w' },
  { name: 'e', code: 'KC_E', keys: 'e', title: 'e' },
  { name: 'r', code: 'KC_R', keys: 'r', title: 'r' },
  { name: 't', code: 'KC_T', keys: 't', title: 't' },
  { name: 'y', code: 'KC_Y', keys: 'y', title: 'y' },
  { name: 'u', code: 'KC_U', keys: 'u', title: 'u' },
  { name: 'i', code: 'KC_I', keys: 'i', title: 'i' },
  { name: 'o', code: 'KC_O', keys: 'o', title: 'o' },
  { name: 'p', code: 'KC_P', keys: 'p', title: 'p' },
  { name: '{\n[', code: 'KC_LBRC', keys: '[', title: '{ [' },
  { name: '}\n]', code: 'KC_RBRC', keys: ']', title: '} ]' },
  { name: '|\n\\', code: 'KC_BSLS', keys: '\\', width: 1500, title: '| \\' },
  { width: 250 },
  { name: 'Del', code: 'KC_DEL', keys: 'delete', title: 'Del' },
  { name: 'End', code: 'KC_END', keys: 'end', title: 'End' },
  { name: 'Page Down', code: 'KC_PGDN', keys: 'pagedown', title: 'Page Down' },
  { width: 250 },
  { name: '7', code: 'KC_P7', keys: 'num_7', title: 'numpad 7' },
  { name: '8', code: 'KC_P8', keys: 'num_8', title: 'numpad 8' },
  { name: '9', code: 'KC_P9', keys: 'num_9', title: 'numpad 9' },
  { name: '+', code: 'KC_PPLS', keys: 'num_add', title: 'numpad +' },
  { width: 0 },

  { name: 'Caps Lock', code: 'KC_CAPS', keys: 'caps_lock', width: 1750, title: 'Caps Lock' },
  { name: 'a', code: 'KC_A', keys: 'a', title: 'a' },
  { name: 's', code: 'KC_S', keys: 's', title: 's' },
  { name: 'd', code: 'KC_D', keys: 'd', title: 'd' },
  { name: 'f', code: 'KC_F', keys: 'f', title: 'f' },
  { name: 'g', code: 'KC_G', keys: 'g', title: 'g' },
  { name: 'h', code: 'KC_H', keys: 'h', title: 'h' },
  { name: 'j', code: 'KC_J', keys: 'j', title: 'j' },
  { name: 'k', code: 'KC_K', keys: 'k', title: 'k' },
  { name: 'l', code: 'KC_L', keys: 'l', title: 'l' },
  { name: ':\n;', code: 'KC_SCLN', keys: ';', title: ': ;' },
  { name: '"\n\'', code: 'KC_QUOT', keys: "'", title: '" \'' },
  { name: 'Enter', code: 'KC_ENT', keys: 'enter', width: 2250, title: 'Enter' },
  { width: 3500 },
  { name: '4', code: 'KC_P4', keys: 'num_4', title: 'numpad 4' },
  { name: '5', code: 'KC_P5', keys: 'num_5', title: 'numpad 5' },
  { name: '6', code: 'KC_P6', keys: 'num_6', title: 'numpad 6' },
  { name: ',', code: 'KC_PCMM', title: 'numpad ,' },
  { width: 0 },

  { name: 'Left Shift', code: 'KC_LSFT', keys: 'shift', width: 2250, title: 'Left shift'  },
  { name: 'z', code: 'KC_Z', keys: 'z', title: 'z' },
  { name: 'x', code: 'KC_X', keys: 'x', title: 'x' },
  { name: 'c', code: 'KC_C', keys: 'c', title: 'c' },
  { name: 'v', code: 'KC_V', keys: 'v', title: 'v' },
  { name: 'b', code: 'KC_B', keys: 'b', title: 'b' },
  { name: 'n', code: 'KC_N', keys: 'n', title: 'n' },
  { name: 'm', code: 'KC_M', keys: 'm', title: 'm' },
  { name: '<\n,', code: 'KC_COMM', keys: ',', title: '< ,' },
  { name: '>\n.', code: 'KC_DOT', keys: '.',  title: '> .' },
  { name: '?\n/', code: 'KC_SLSH', keys: '/', title: '? /' },
  { name: 'Right Shift', code: 'KC_RSFT', width: 2750, title: 'Right shift' },
  { width: 1250 },
  { name: 'Up', code: 'KC_UP', keys: 'up', title: 'Up' },
  { width: 1250 },
  { name: '1', code: 'KC_P1', keys: 'num_1', title: 'numpad 1' },
  { name: '2', code: 'KC_P2', keys: 'num_2', title: 'numpad 2' },
  { name: '3', code: 'KC_P3', keys: 'num_3', title: 'numpad 3' },
  { name: '=', code: 'KC_PEQL', title: 'numpad =' },
  { width: 0 },

  { name: 'Left Ctrl', code: 'KC_LCTL', keys: 'ctrl', width: 1250, title: 'Left Ctrl'},
  { name: 'Left OS', code: 'KC_LGUI', keys: 'cmd', width: 1250   , title: 'Left OS'},
  { name: 'Left Alt', code: 'KC_LALT', keys: 'alt', width: 1250  , title: 'Left Alt'},
  { name: 'Space', code: 'KC_SPC', keys: 'space', width: 6250    , title: 'Space'},
  { name: 'Right Alt', code: 'KC_RALT', width: 1250              , title: 'Right Alt'},
  { name: 'Right OS', code: 'KC_RGUI', width: 1250               , title: 'Right OS'},
  { name: 'Menu', code: 'KC_APP', width: 1250                    , title: 'Menu'},
  { name: 'Right Ctrl', code: 'KC_RCTL', width: 1250             , title: 'Right Ctrl'},
  { width: 250 },
  { name: 'Left', code: 'KC_LEFT', keys: 'left', title: 'Left' },
  { name: 'Down', code: 'KC_DOWN', keys: 'down', title: 'Down' },
  { name: 'Right', code: 'KC_RGHT', keys: 'right', title: 'Right' },
  { width: 250 },
  { name: '0', code: 'KC_P0', keys: 'num_0', width: 2000, title: '0' },
  { name: '.', code: 'KC_PDOT', keys: 'num_decimal', title: 'numpad .' },
  { name: 'Enter', code: 'KC_PENT', keys: 'num_enter', title: 'numpad enter' },

  { label: 'Shifted symbols', width: 'label' },

  { name: '~', code: 'KC_TILD', keys: '~', title: '~' },
  { name: '!', code: 'KC_EXLM', keys: '!', title: '!' },
  { name: '@', code: 'KC_AT',   keys: '@', title: '@' },
  { name: '#', code: 'KC_HASH', keys: '#', title: '#' },
  { name: '$', code: 'KC_DLR',  keys: '$', title: '$' },
  { name: '%', code: 'KC_PERC', keys: '%', title: '%' },
  { name: '^', code: 'KC_CIRC', keys: '^', title: '^' },
  { name: '&', code: 'KC_AMPR', keys: '&', title: '&' },
  { name: '*', code: 'KC_ASTR', keys: '*', title: '*' },
  { name: '(', code: 'KC_LPRN', keys: '(', title: '(' },
  { name: ')', code: 'KC_RPRN', keys: ')', title: ')' },
  { name: '_', code: 'KC_UNDS', keys: '_', title: '_' },
  { name: '+', code: 'KC_PLUS', keys: '+', title: '+' },
  { name: '{', code: 'KC_LCBR', keys: '{', title: '{' },
  { name: '}', code: 'KC_RCBR', keys: '}', title: '}' },
  { name: '<', code: 'KC_LT',   keys: '<', title: '<' },
  { name: '>', code: 'KC_GT',   keys: '>', title: '>' },
  { name: ':', code: 'KC_COLN', keys: ':', title: ':' },
  { name: '|', code: 'KC_PIPE', keys: '|', title: '|' },
  { name: '?', code: 'KC_QUES', keys: '?', title: '?' },
  { name: '"', code: 'KC_DQUO', keys: '"', title: '"' }
];
