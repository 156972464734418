import base64js from 'base64-js';

const uf2Fields = {
  magicStart0: 0,
  magicStart1: 1,
  flags:       2,
  targetAddr:  3,
  payloadSize: 4,
  blockNo:     5,
  numBlocks:   6,
  familyID:    7,
  data:        32,
  magicEnd:    127
};

function buildUf2Block(arrbuf, uint8Data, blockNo, numBlocks) {
  let uint32Arr = new Uint32Array(arrbuf);
  let uint8Arr = new Uint8Array(arrbuf);
  uint32Arr[(blockNo * 512) / 4 + uf2Fields.magicStart0] = 0x0a324655;
  uint32Arr[(blockNo * 512) / 4 + uf2Fields.magicStart1] = 0x9e5d5157;
  uint32Arr[(blockNo * 512) / 4 + uf2Fields.flags] = 0x00002000;
  uint32Arr[(blockNo * 512) / 4 + uf2Fields.targetAddr] =
    0x000aa000 + 256 * blockNo; // TODO: 从info.json中读取起始地址0x000aa000
  uint32Arr[(blockNo * 512) / 4 + uf2Fields.payloadSize] = 256;
  uint32Arr[(blockNo * 512) / 4 + uf2Fields.blockNo] = blockNo;
  uint32Arr[(blockNo * 512) / 4 + uf2Fields.numBlocks] = numBlocks;
  uint32Arr[(blockNo * 512) / 4 + uf2Fields.familyID] = 0xe7352840;
  uint32Arr[(blockNo * 512) / 4 + uf2Fields.magicEnd] = 0x0ab16f30;
  for (let j = 0; j < 256 && blockNo * 256 + j < uint8Data.length; j++) {
    uint8Arr[blockNo * 512 + uf2Fields.data + j] = uint8Data[blockNo * 256 + j];
  }
}

function arrayToUf2ArrBuf(uint8) {
  let strLen = uint8.length;
  let numBlocks = Math.ceil(strLen / 256); // 一个块中有256个有效数据

  let arrbuf = new ArrayBuffer(512 * numBlocks);

  for (let i = 0; i < numBlocks; i++) {
    buildUf2Block(arrbuf, uint8, i, numBlocks);
  }
  return arrbuf;
}

function strToUf2Base64(str) {
  let charArr = new Array(str.length + 1);
  let i = 0;
  for (; i < str.length; i++) charArr[i] = str.charCodeAt(i);
  charArr[i] = 0;

  let uf2Arr = arrayToUf2ArrBuf(charArr);
  return base64js.fromByteArray(new Uint8Array(uf2Arr));
}

function uf2ArrBufToStr(arrBuf) {
  if (arrBuf.byteLength % 512 != 0) throw 'not correct format.';

  let numBlocks = arrBuf.byteLength / 512;
  let uint32Arr = new Uint32Array(arrBuf);
  let uint8Arr = new Uint8Array(arrBuf);
  let strArr = new Array(256 * numBlocks);
  let result = '';

  if (uint32Arr[uf2Fields.numBlocks] != numBlocks) throw 'not correct format.';

  for (let i = 0; i < numBlocks; i++) {
    let blockNo = uint32Arr[(i * 512) / 4 + uf2Fields.blockNo]; // 4:uint32是4字节
    for (let j = 0; j < 256; j++) {
      strArr[blockNo * 256 + j] = uint8Arr[i * 512 + uf2Fields.data + j];
    }
  }

  for (let i = 0; i < strArr.length; i++) {
    if (strArr[i] == 0) break;
    result += String.fromCharCode(strArr[i]);
  }

  return result;
}

export { strToUf2Base64, uf2ArrBufToStr };
