export default {
  name: 'dsa-milkshake',
  override: {
    KC_ESC: 'esc',
    KC_BSPC: 'backspace',
    KC_ENT: 'enter',
    KC_RGUI: 'os',
    KC_LGUI: 'os',
    KC_LALT: 'alt',
    KC_RALT: 'alt'
  }
};
