export default {
  MAX_X: 800,
  KEY_WIDTH: 50,
  KEY_HEIGHT: 50,
  SWAP_KEY_WIDTH: 30,
  SWAP_KEY_HEIGHT: 30,
  KEY_X_SPACING: 55,
  KEY_Y_SPACING: 55,
  SCALE: 1
};
