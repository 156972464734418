export default {
  name: 'gmk-space-cadet',
  override: {
    KC_F1: 'mod',
    KC_F2: 'mod',
    KC_F3: 'mod',
    KC_F4: 'mod',
    KC_F9: 'mod',
    KC_F10: 'mod',
    KC_F11: 'mod',
    KC_F12: 'mod'
  }
};
