<template>
  <!-- prettier-ignore -->
  <div
    draggable
    :id="myid"
    class="key key-container macro-step"
    :class="myclasses"
    :style="mystyles"
    @click="clicked"
    @dragstart="dragstart"
    @dragend="dragend"
    @drop.stop="droppedContents"
    @dragover.prevent="dragover"
    @dragenter="dragenter"
    @dragleave="dragleave"
    ><div>{{ displayName }}
      <div
        v-if="stepIsKey"
        class="key-contents"
        :class="contentClasses"
        @dragenter.prevent="dragenterContents"
        @dragleave.prevent="dragleaveContents"
        @click.prevent.stop="clickContents"
        >{{ contents }}</div>
      <div><input
        v-if="stepIsNum"
        class="key-layer-input"
        :class="errorClasses"
        type="number"
        min="0"
        max="5000"
        :value="stepValue"
        @focus="focus"
        @blur="blur"
        @input="input"
        /></div>
      <div><input
        v-if="stepIsStr"
        class="key-layer-input step-str"
        type="text"
        :value="stepValue"
        @focus="focus"
        @blur="blur"
        @input="input"
        /></div>
    </div><div
        v-if="visible"
        class="remove"
        @click.stop="remove"
      >x</div></div>
</template>
<script>
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
import isUndefined from 'lodash/isUndefined';
import { mapMutations, mapActions } from 'vuex';
import BaseKey from './BaseKey';
export default {
  name: 'macrostep-key',
  extends: BaseKey,
  data() {
    return {
      contentsInHover: false
    };
  },
  computed: {
    stepIsKey() {
      return (
        this.meta.code[0]=='T' || 
        this.meta.code[0]=='D' || 
        this.meta.code[0]=='U'
      );
    },
    stepIsNum() {
      return (
        this.meta.code[0]=='W' || 
        this.meta.code[0]=='I'
      );
    },
    stepIsStr() {
      return (
        this.meta.code[0]=='S'
      );
    },
    contents() {
      if (this.meta.content) {
        return this.formatName(this.meta.content.name);
      }
      return '';
    },
    stepValue() {
      return this.meta.content.code;
    },
    contentClasses() {
      let classes = [];
      if (this.contentsInHover) {
        classes.push('overme');
      }
      if (this.isContentSelected) {
        classes.push('keycode-select');
      }
      console.log('contentClasses ', classes);
      return classes.join(' ');
    },
    errorClasses() {
      if (this.error) {
        return 'input-error';
      }
      return '';
    }
  },
  methods: {
    ...mapMutations('app', ['setHasErrors', 'setHasNoErrors']),
    ...mapMutations('keymap', ['setContents']),
    // ...mapMutations('keyboardSetting', []),
    ...mapActions('keyboardSetting', ['removeMacroStep','setMacroStepKey','swapMacroStep']),
    dragenterContents(e) {
      if (e.target.classList.contains('key-contents')) {
        this.contentsInHover = true;
      }
    },
    dragleaveContents() {
      this.contentsInHover = false;
    },
    droppedContents(e) {
        let json = JSON.parse(e.dataTransfer.getData('application/json'));
      if (e.target.classList.contains('key-contents')) {
        console.log('drop on contents ', e);
        if (isUndefined(json.type)) {
          this.setContents({
            index: this.id,
            key: {
              name: json.name,
              code: json.code,
              type: json.type
            }
          });
        } else {
          // TBD animate error on element
        }
        this.dragleave(e);
        this.dragleaveContents(e);
        return true;
      }
      this.setSelected(this.id);
      if (json.action === 'swap') {
        console.log(`swapping macro key ${json.id} with ${this.id}`);
        this.swapMacroStep({
          srcIndex: json.id,
          dstIndex: this.id
        });
      } else {
        // this.setKeycode({ _code: json.code, layer: json.layer });
      }
      this.dragleave();
    },
    remove() {
      console.log("remove: "+this.id);
      this.removeMacroStep(this.id);
    },
    input(e) {
      let newContent = e.target.value
      if(this.stepIsNum) {
        newContent = parseInt(newContent, 10);
        if (!isNaN(newContent) && isNumber(newContent)) {
          this.error = newContent < 0 || newContent > 5000;
        }
      }

      if (this.error) {
        this.setHasErrors();
      } else {
        this.setMacroStepKey({
          stepId: this.id,
          name: this.meta.name,
          code: newContent
        });
        console.log("new val:" + this.meta.content.code);
        this.setHasNoErrors();
      }
    },
    blur() {
      this.startListening();
      this.setSelected(undefined);
    },
    focus() {
      this.stopListening();
    }
  }
};
</script>
<style>
.key-contents.overme {
  border-radius: 4px;
}
.macro-step {
  display:inline-block;
  position:relative;
  min-width: 50px;
  margin: 7px 3px;
  /* */
}
.macro-step .key-contents {
  width: 30px;
  /* */
}
.macro-step .key-layer-input {
    width: 45px;
    font-size:0.7rem;
}
.macro-step .step-str {
    width: 100%;
    font-size:0.7rem;
}
</style>
