<!--
  use component is syntax to avoid inline block space issues
  @see https://css-tricks.com/fighting-the-space-between-inline-block-elements/
-->
<template>
  <div id="keyboardMain-section">
    <div id="keyboardMain">
      <div class="tabs">
        <!--span
          class="tab"
          :class="classes(key)"
          v-for="(key, index) in this.Tabs"
          :key="index"
          @click="changeActive(key)"
          >{{ $t('keyboardMainTab.' + key + '.label') }}</span
        -->
        <span
          class="tab"
          :class="classes('KeymapPanel')"
          @click="changeActive('KeymapPanel')"
          >{{ $t('keyboardMainTab.KeymapPanel.label') }}</span
        >
        <span
          class="tab"
          v-if="showLedSetting"
          :class="classes('LEDSetting')"
          @click="changeActive('LEDSetting')"
          >{{ $t('keyboardMainTab.LEDSetting.label') }}</span
        >
        <span
          class="tab"
          :class="classes('Macros')"
          @click="changeActive('Macros')"
          >{{ $t('keyboardMainTab.Macros.label') }}</span
        >
      </div>
      <!--div class="tabs">
        <span
          class="tab"
          :class="classes('VisualKeymap')"
          @click="changeActive('VisualKeymap')"
          :title="VisualKeymap"
          >VisualKeymap</span
        >
      </div>
      <div class="tabs">
        <span
          class="tab"
          :class="classes('LEDSetting')"
          @click="changeActive('LEDSetting')"
          :title="LEDSetting"
          >LEDSetting</span
        >
      </div-->
      <div class="tab-area">
        <component v-bind:is="active" v-bind="currentProperties"></component>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import KeymapPanel from '@/components/KeymapPanel';
import LEDSetting from '@/components/LEDSetting';
import Macros from '@/components/Macros';

export default {
  name: 'KeyboardMain',
  components: {
    KeymapPanel,
    LEDSetting,
    Macros
  },
  props: {},
  data() {
    let active = 'KeymapPanel';
    let tabs;
    tabs = ['KeymapPanel', 'LEDSetting','Macros'];
    return {
      Tabs: tabs,
      active: active
    };
  },
  computed: {
    ...mapGetters('app', ['ledCount', 'rgbCount']),
    isProduction() { return (process.env.NODE_ENV === 'production'); },
    //   v-bind="currentProperties"
    currentProperties() {
      if (this.active === 'KeymapPanel') {
        return { profile: false };
      }
      return {};
    },
    showLedSetting() {
      return (this.ledCount + this.rgbCount ) >0;
    }
  },
  methods: {
    classes(current) {
      let classes = [];
      if (current === this.active) {
        classes.push('active');
      }
      return classes.join(' ');
    },
    changeActive(index) {
      this.active = index;
    }
  }
};
</script>
<style scoped>
#keyboardMain {
  border: none;
  margin-bottom: 20px;
  margin-top: 10px;
  /* display: grid;  竖向tab 增加*/
  /* grid-template-columns: 29px auto;  竖向tab  增加*/
}
.tabs {
  /* display: inline-block;  竖向tab, grid */
  display: grid;  /* 竖向tab, grid */
  grid-template: auto / repeat(6, minmax(120px, 200px));  /* 竖向tab, grid */
}
.tab {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-top: 1px solid;
  border-left: 1px solid;
  /* border-bottom: 1px solid; 竖向tab, right */
  border-right: 1px solid; /* 竖向tab, right */
  padding: 2px;
  margin-right: 2px; /* 竖向tab = -1, 2 */
  border-radius: 4px 4px 0 0; /* 竖向tab =4004, 4 4 0 0 */
  display: inline-block;
  opacity: 0.55;
  z-index: 100;
  cursor: pointer;
  margin-bottom: -1px; /* 竖向tab =0, -1 */
  background-color: #eee;
  /* writing-mode:vertical-rl;  竖向tab  增加 */
  /* width: 23px; 竖向tab  增加 */
  /* height: 100px; 竖向tab  增加 */
}
.end-tab {
  grid-column: -1;
  justify-self: end;
}
.end-tab input {
  padding: 3px 7px;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  width: 90%;
  float: right;
}
.tab-area {
  height: auto;
  padding: 10px 5px;
  border: 1px solid;
  border-radius: 0 4px 4px 4px;
  background-color: #fff;
  text-align: left;
}
.tab.active {
  opacity: 1;
  background-color: #fff;
}
.keycode.isoenter {
  position: absolute;
  left: 520px;
  top: 117px;
  height: 69px;
}
.desaturated {
  opacity: 0.3;
}
.keycode-search-icon {
  position: absolute;
  right: 5px;
  top: 11px;
  color: #999;
}
.tab span {
  margin-left: 4px;
}
</style>
