const ROW1 = [
  'KC_ESC',
  'KC_F1',
  'KC_F2',
  'KC_F3',
  'KC_F4',
  'KC_F5',
  'KC_F6',
  'KC_F7',
  'KC_F8',
  'KC_F9',
  'KC_F10',
  'KC_F11',
  'KC_F12',
  'KC_PSCR',
  'KC_SLCK',
  'KC_PAUS'
];
const ROW2 = [
  'KC_GRV',
  'KC_1',
  'KC_2',
  'KC_3',
  'KC_4',
  'KC_5',
  'KC_6',
  'KC_7',
  'KC_8',
  'KC_9',
  'KC_0',
  'KC_MINS',
  'KC_EQL',
  'KC_BSPC',
  'KC_INS',
  'KC_HOME',
  'KC_PGUP',
  'KC_NLCK',
  'KC_PSLS',
  'KC_PAST',
  'KC_PMNS'
];
const ROW3 = [
  'KC_TAB',
  'KC_Q',
  'KC_W',
  'KC_E',
  'KC_R',
  'KC_T',
  'KC_Y',
  'KC_U',
  'KC_I',
  'KC_O',
  'KC_P',
  'KC_LBRC',
  'KC_RBRC',
  'KC_BSLS',
  'KC_DEL',
  'KC_END',
  'KC_PGDN',
  'KC_P7',
  'KC_P8',
  'KC_P9',
  'KC_PPLS'
];
const ISO_ROW3 = [
  'KC_TAB',
  'KC_Q',
  'KC_W',
  'KC_E',
  'KC_R',
  'KC_T',
  'KC_Y',
  'KC_U',
  'KC_I',
  'KC_O',
  'KC_P',
  'KC_LBRC',
  'KC_RBRC',
  'KC_ENT',
  'KC_DEL',
  'KC_END',
  'KC_PGDN',
  'KC_P7',
  'KC_P8',
  'KC_P9',
  'KC_PPLS'
];
const ROW4 = [
  'KC_CAPS',
  'KC_A',
  'KC_S',
  'KC_D',
  'KC_F',
  'KC_G',
  'KC_H',
  'KC_J',
  'KC_K',
  'KC_L',
  'KC_SCLN',
  'KC_QUOT',
  'KC_ENT',
  'KC_P4',
  'KC_P5',
  'KC_P6'
];
const ISO_ROW4 = [
  'KC_CAPS',
  'KC_A',
  'KC_S',
  'KC_D',
  'KC_F',
  'KC_G',
  'KC_H',
  'KC_J',
  'KC_K',
  'KC_L',
  'KC_SCLN',
  'KC_QUOT',
  'KC_NUHS',
  'KC_P4',
  'KC_P5',
  'KC_P6'
];
const ROW5 = [
  'KC_LSFT',
  'KC_Z',
  'KC_X',
  'KC_C',
  'KC_V',
  'KC_B',
  'KC_N',
  'KC_M',
  'KC_COMM',
  'KC_DOT',
  'KC_SLSH',
  'KC_RSFT',
  'KC_UP',
  'KC_P1',
  'KC_P2',
  'KC_P3',
  'KC_PENT'
];
const ISO_ROW5 = [
  'KC_LSFT',
  'KC_NUBS',
  'KC_Z',
  'KC_X',
  'KC_C',
  'KC_V',
  'KC_B',
  'KC_N',
  'KC_M',
  'KC_COMM',
  'KC_DOT',
  'KC_SLSH',
  'KC_RSFT',
  'KC_UP',
  'KC_P1',
  'KC_P2',
  'KC_P3',
  'KC_PENT'
];
const ROW6 = [
  'KC_LCTL',
  'KC_LGUI',
  'KC_LALT',
  'KC_SPC',
  'KC_RALT',
  'KC_RGUI',
  'KC_APP',
  'KC_RCTL',
  'KC_LEFT',
  'KC_DOWN',
  'KC_RGHT',
  'KC_P0',
  'KC_PDOT'
];
const ANSI = [...ROW1, ...ROW2, ...ROW3, ...ROW4, ...ROW5, ...ROW6];
const ISO = [...ROW1, ...ROW2, ...ISO_ROW3, ...ISO_ROW4, ...ISO_ROW5, ...ROW6];
export default { ANSI, ISO };
