import Vue from 'vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import size from 'lodash/size';
import isUndefined from 'lodash/isUndefined';
import reduce from 'lodash/reduce';

const state = {
  leds: [{}],
  rgbs: {},
  keyboardSetting: {},
  macros: []
};

const macroStepTypeString = {
  "T": "TAP",
  "D": "DOWN",
  "U": "UP",
  "W": "DELAY",
  "I": "INTERVAL",
  "S": "STR",
};

function addMacroStep(state,{macroId, actType}) {
  // newStepId从1000起，找现有的id最大的，然后+1
  let newStepId = 1+state.macros[macroId].reduce(
    (max, el) => {
      return el.id>max? el.id: max;
    },
    (macroId+1)*1000-1
  );
  let val;
  actType = actType.toUpperCase();
  switch( actType[0] ) {
    case 'T':
    case 'D':
    case 'U':
      val = {
          name:"N/A",
          code:"KC_NO",
          type:""
        };
      break;
    case 'W':
    case 'I':
      val = {
          name:"DELAY",
          code:"100",
          type:""
        };
      break;
    case 'S':
      val = {
          name:"STR",
          code:"",
          type:""
        };
      break;
    default:
      return;
  }
  state.macros[macroId].push( {
    id:newStepId,
    meta:{
      name:macroStepTypeString[actType[0]],
      code:actType[0]+"(kc)",
      type:"container",
      content: val
      }
    }
  );
};

function setMacroStepKey(state,{stepId, name, code}) {
  let macroId = Math.floor(stepId / 1000) - 1;
  if( 0<=macroId && macroId<state.macros.length) {
    for(let i=0; i<state.macros[macroId].length; i++) {
      if(state.macros[macroId][i].id==stepId) {
        state.macros[macroId][i].meta.content.name = name;
        state.macros[macroId][i].meta.content.code = code;
      }
    }
  }
};

const getters = {
  // ...mapGetters('app', [
  //   'ledCount'
  // ]),
  ledSettingCount: state => size(state.leds),
  rgbSettingCount: state => size(state.rgbs),
  // leds: state => state.leds,
  exportLeds: state => state.leds,
  exportRgbs: state => state.rgbs,
  exportKeyboardSetting: state => state.keyboardSetting,
  macros: state => state.macros,  
  exportMacros: state => {
    let ret = state.macros.map(
      (macro, pos) => {
        let steps = new Array();
        for(let i=0; i<macro.length; i++) {
          let code = macro[i].meta.code;
          let cont = macro[i].meta.content.code;
          if(code[0]=='S') {
            // cont = cont
            //   .replace(/\\r/g, '\r')
              // .replace('\\n', '\n')
              // .replace('\\t', '\t')
              // .replace('\\\\', '\\')
            ;
          }
          let astep = 
            code.replace("kc", cont);
          steps.push(astep);

        }
        return steps;
      }
    );
    return ret;
  },
};

const actions = {
  updateLeds({ state, commit, dispatch }, leds) {
    commit('setLeds', leds);
  },
  updateLedAt({ state, commit, dispatch }, newValObj) {
    commit('updateLedAt', newValObj);
  },
  updateRgbs({ state, commit, dispatch }, rgbs) {
    commit('setRgbs', rgbs);
  },
  updateRgbAt({ state, commit, dispatch }, newValObj) {
    commit('updateRgbAt', newValObj);
  },
  removeMacroStep({ state, commit, dispatch }, stepId) {
    commit('removeMacroStep', stepId);
  },
  setMacroStepKey({ state, commit, dispatch }, newValObj){
    commit('setMacroStepKey', newValObj);
  },
  swapMacroStep({ state, commit, dispatch }, newValObj){
    commit('swapMacroStep', newValObj);
  },
  loadMacrosFromJson({ state, commit, dispatch }, newValObj){
    commit('loadMacrosFromJson', newValObj);
  },
};

const mutations = {
  setLeds(state, leds) {
    if(leds===undefined)
      return;
    // TODO: 验证数量，超限不改
    for(var i=0; i<leds.length; i++) {
      if(state.leds[i]===undefined)
        state.leds.push({fn:null, en:false, enwl:false});
      state.leds[i].fn = leds[i].fn;
      state.leds[i].en = leds[i].en;
      state.leds[i].enwl = leds[i].enwl;
    }
    // state.leds = leds;
    // console.log('setting leds[].' + leds);
  },
  updateLedAt(state, newValObj) {
    // TODO: 验证数量，超限不改
    if (!isUndefined(newValObj.fn)) state.leds[newValObj.id].fn = newValObj.fn;
    if (!isUndefined(newValObj.en)) state.leds[newValObj.id].en = newValObj.en;
    if (!isUndefined(newValObj.enwl))
      state.leds[newValObj.id].enwl = newValObj.enwl;
  },
  setRgbs(state, rgbs) {
    state.rgbs = rgbs;
    console.log('setting rgbs[].' + JSON.stringify(rgbs));
  },
  updateRgbAt(state, newValObj) {
    for (var key in newValObj) {
      state.rgbs[key].color = newValObj[key].color;
      state.rgbs[key].enwl = newValObj[key].enwl;
      state.rgbs[key].en = newValObj[key].en;
    }
    console.log('updated rgbs[].' + JSON.stringify(state.rgbs));
  },
  updateKeyboardSetting(state, setting) {
    for (var key in setting) {
      state.keyboardSetting[key] = setting[key];
    }
  },
  addNewMacro(state) {
    state.macros.push(new Array());
  },
  removeMacroById(state, idx) {
    state.macros.splice(idx,1);
  },
  addMacroStep(state,{macroId, actType}) {
    addMacroStep(state,{macroId, actType});
  },
  removeMacroStep(state, stepId){
    let macroId = Math.floor(stepId / 1000) - 1;
    if( 0<=macroId && macroId<state.macros.length) {
      for(let i=0; i<state.macros[macroId].length; i++) {
        if(state.macros[macroId][i].id==stepId)
          state.macros[macroId].splice(i,1);
      }
    }
  },
  setMacroStepKey(state,{stepId, name, code}) {
    setMacroStepKey(state,{stepId, name, code});
  },
  swapMacroStep(state, {srcIndex, dstIndex}) {
    let srcMacroId = Math.floor(srcIndex / 1000) - 1;
    let dstMacroId = Math.floor(dstIndex / 1000) - 1;
    if(srcMacroId!=dstMacroId)
      return;
    
    let src=-1, dst=-1;
    for(let i=0; i<state.macros[srcMacroId].length; i++) {
      let id=state.macros[srcMacroId][i].id;
      if     (id==srcIndex)
        src = i;
      else if(id==dstIndex)
        dst = i;
      if(src>=0 && dst>=0) {
        // 交换：
        // state.macros[srcMacroId].splice(dst,1,...state.macros[srcMacroId].splice(src, 1 , state.macros[srcMacroId][dst]));
        state.macros[srcMacroId].splice(dst, 0, state.macros[srcMacroId].splice(src,1)[0]);
        return;
      }
        
    }

  },
  loadMacrosFromJson(state, data) {
    state.macros.splice(0);
    if(data===undefined)
      return;
    let store = this;
    for(var macroId=0; macroId<data.length; macroId++) {
      state.macros.push(new Array());
      for(var stepIdx=0; stepIdx<data[macroId].length; stepIdx++) {
        let actType = data[macroId][stepIdx][0];
        let stepVal = data[macroId][stepIdx].slice(2, -1);
        let stepName = actType;
        if(actType=='T'||actType=='D'||actType=='U') {
          let { name, code, type } = store.getters['keycodes/lookupKeycode'](stepVal);
          stepName = name;
        }
        addMacroStep(state,{macroId, actType});
        setMacroStepKey(state,{
          stepId: (macroId+1)*1000 +stepIdx, 
          name: stepName,
          code: stepVal
        });
      }      
    }
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
