export default {
  name: 'sa-vilebloom',
  override: {
    KC_ESC: 'accent1',
    KC_F1: 'accent1',
    KC_F2: 'accent1',
    KC_F3: 'accent1',
    KC_F4: 'accent2',
    KC_F5: 'accent3',
    KC_GRV: 'accent1',
    KC_GESC: 'accent1',
    KC_1: 'accent1',
    KC_2: 'accent1',
    KC_3: 'accent1',
    KC_4: 'accent2',
    KC_5: 'accent2',
    KC_6: 'accent3',
    KC_T: 'accent3',
    KC_F: 'accent3',
    KC_C: 'accent3',
    KC_TAB: 'accent1',
    KC_Q: 'accent1',
    KC_W: 'accent1',
    KC_CAPS: 'accent1',
    KC_A: 'accent1',
    KC_LSFT: 'accent1',
    KC_NUBS: 'accent1',
    KC_LCTL: 'accent1',
    KC_E: 'accent2',
    KC_R: 'accent2',
    KC_S: 'accent2',
    KC_D: 'accent2',
    KC_Z: 'accent2',
    KC_X: 'accent2',
    KC_LGUI: 'accent2',
    KC_LALT: 'accent2',
    KC_P1: 'accent3',
    KC_P5: 'accent3',
    KC_P9: 'accent3',
    KC_PMNS: 'accent3',
    KC_P2: 'accent2',
    KC_P3: 'accent2',
    KC_P6: 'accent2',
    KC_PPLS: 'accent2',
    KC_P0: 'accent2',
    KC_PDOT: 'accent1',
    KC_PENT: 'accent1'
  }
};
